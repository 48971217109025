<template>
  <div>
    <div class="header">
      <van-nav-bar
        :title="$t('fbuy.title')"
        left-arrow
        :right-text="$t('fbuy.title2')"
        @click-left="goback"
        @click-right="toserver"
      />
    </div>
    <div class="maincontent">
      <van-row class="top">
        <van-row>
          <span>向商家付款</span>
        </van-row>
        <van-row class="htext">
          <span>{{ info.total }}</span>
        </van-row>
        <van-row class="time" v-if="info.status === '0'">
          {{ $t('fbuy.time') }}：<van-count-down
            :time="time"
            format="mm:ss"
            @finish="finish"
          />
        </van-row>
        <van-row v-if="info.status === '0'">
          您正在向{{ info.nickname }}购买{{ info.trade_num }}个
        </van-row>
      </van-row>
      <van-row class="info">
        <van-cell
          :title="item.field"
          v-for="(item, index) in payment"
          :key="index"
          :value="item.value"
        />
      </van-row>
      <van-button v-if="info.status === '0'" class="submitBtn" @click="pay">{{
        $t('fbuy.finish')
      }}</van-button>
    </div>
    <!-- 取消弹窗 -->
    <van-popup v-model="show" class="operation-buyUp">
      <van-row style="margin:20px auto">
        <h3>{{ $t('fbuy.tips') }}</h3>
        <span>{{ $t('fbuy.tipstext') }}</span>
      </van-row>
      <van-row>
        <van-col span="12"
          ><van-button @click="close">{{
            $t('common.cancel')
          }}</van-button></van-col
        >

        <van-col span="12"
          ><van-button @click="cancel">{{
            $t('common.confirm')
          }}</van-button></van-col
        >
      </van-row>
    </van-popup>
  </div>
</template>
<script>
export default {
  data() {
    return {
      info: {},
      time: 20 * 60 * 1000,
      payment: [],
      show: false
    }
  },
  created() {
    this.getinfo()
  },
  methods: {
    async getinfo() {
      const { data } = await this.$http.get(
        '/home/trade/buy_pay/id/' + this.$route.params.id
      )
      if (data) {
        if (data.code === 200) {
          this.info = data.data
          this.time = data.data.times * 1000
          this.payment = JSON.parse(data.data.payment.contact_info)
        }
      }
    },
    // 倒计时结束 取消订单
    async finish() {
      this.cancel()
    },
    // 完成付款
    async pay() {
      const { data } = await this.$http.post('/home/trade/buy_pay', {
        id: this.info.id
      })
      if (data) {
        if (data.code === 200) {
          this.$toast.success(this.$t('common.success'))
          this.getinfo()
        } else {
          this.$toast.fail(this.getlang(data.msg, localStorage.getItem('lang')))
        }
      }
    },
    // 联系卖家
    toserver() {
      this.$router.push(
        '/trade/shopping/trading/contact/' + this.$route.params.id
      )
    },
    // 取消订单
    async cancel() {
      if (this.show) {
        this.show = false
      }
      const { data } = await this.$http.post('/home/trade/cancel', {
        id: this.info.id
      })
      if (data) {
        if (data.code === 200) {
          this.$router.push('/trade/shopping/trading')
        } else {
          this.$toast.fail(this.getlang(data.msg, localStorage.getItem('lang')))
        }
      }
    },
    // 返回
    goback() {
      if (this.info.status === '0') {
        this.show = true
      } else {
        this.$router.go('-1')
      }
    },
    // 关闭弹窗
    close() {
      this.show = false
    }
  }
}
</script>
<style lang="less" scoped>
.header {
  /deep/.van-nav-bar__title {
    font-size: 1rem;
    color: #2c3e50;
  }
  /deep/.van-nav-bar__text {
    font-size: 0.98667rem;
    color: #2c3e50;
  }
}
.maincontent {
  padding: 56px 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  font-size: 0.93333rem;
  background: #f7f7f7;
  min-height: 100vh;
  .top {
    background: #fff;
    padding: 30px 20px;
    text-align: center;
    .htext {
      font-size: 3.2rem;
    }
    .time {
      color: #d90012;
      line-height: 2rem;
      margin-top: 5px;
    }
    .van-count-down {
      display: inline-block;
      color: #d90012;
      font-size: 1.4rem;
    }
  }
  .info {
    margin-top: 8px;
    .van-cell {
      line-height: 30px;
    }
    .van-cell__title {
      text-align: left;
    }
    .van-cell__value {
      color: #888;
    }
  }
  .submitBtn {
    margin: 10% auto 0 auto;
    width: 80%;
    letter-spacing: 0.53333rem;
    height: 3rem;
    font-size: 0.93333rem;
    color: #fff;
    background-color: #004ea3;
    border-radius: 0.53333rem;
    border: none;
    box-shadow: 0 0 1.33333rem #004ea3;
  }
}
// 交易弹出层
.operation-buyUp {
  width: 75%;
  border-radius: 0.4rem;
  background-color: #fff !important;
  color: #fff !important;
  overflow: hidden;
  span {
    color: #000;
  }
  h3 {
    padding-top: 4%;
    padding-bottom: 4%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 0.02667rem solid #fff;
    font-weight: 400;
    color: #000;
    font-size: 1.1rem;
  }

  .van-button {
    width: 100%;
    margin: 0;
    padding-top: 4%;
    padding-bottom: 4%;
    letter-spacing: 0.53333rem;
    color: #fff;
    background-color: #004ea3;
    border: none;
    border-top: 0.02667rem solid #fff;
    font-size: 1.06667rem;
    .van-button__text {
      color: #fff;
    }
  }
}
</style>
